
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Validate } from '@/mixin/validator';
import MultipleImageUpload from '@/component/multiple-image-upload.vue';
import SingleImageUpload from '@/component/single-image-upload.vue';
import { request as axios } from '@/util/request';

@Component({
  name: 'Share',
  components: {
    MultipleImageUpload,
    SingleImageUpload
  },
})
export default class Share extends Mixins(Validate) {
  sharecard_title: any = '纽澜地' //标题
  sharecard_description: any = '鲜美生活 快速送达' //描述
  sharecard_thumbnap: any = '' //缩略图
  preview_image: string = ''; // 预览图片的路径
  preview_visible: boolean = false; // 预览图片的模态框显示状态
  sharecard_title_err: boolean = false
  sharecard_description_err: boolean = false
  sharecard_thumbnap_err: boolean = false
  max_title = 100
  max_description = 200
  // 显示模态窗，改变模态窗中的图片路径
  handlePreview(imageUrl: string) {
    this.preview_image = imageUrl;
    this.preview_visible = true;
  }
  // 关闭模态窗
  handlePreviewCancel() {
    this.preview_visible = false;
  }
  // 通用图片上传校验
  beforeCommonUpload(file: any) {
    console.log(file)
    if (file.size / 1024 > 32) {
      this.$message.error('上传图片大小不能超过32kb');
      this.sharecard_thumbnap_err = true
      return false;
    } else {
      this.sharecard_thumbnap_err = false
    }
  }
  //检查必填项
  inputChange(type: any) {
    if (type == 'sharecard_title') {
      if (this.sharecard_title == '') {
        this.sharecard_title_err = true
        return false
      } else {
        this.sharecard_title_err = false
        return true
      }
    } else if (type == 'sharecard_description') {
      if (this.sharecard_description == '') {
        this.sharecard_description_err = true
        return false
      } else {
        this.sharecard_description_err = false
        return true
      }
    } else if (type == 'sharecard_thumbnap') {
      console.log(this.sharecard_thumbnap)
      if (this.sharecard_thumbnap == '') {
        this.sharecard_thumbnap_err = true
        return false
      } else {
        this.sharecard_thumbnap_err = false
        return true
      }
    }
  }
  //保存
  async save() {
    const params = {
      sharecard_title: this.sharecard_title,
      sharecard_description: this.sharecard_description,
      sharecard_thumbnap: this.sharecard_thumbnap
    }
    //console.log(params)
    //console.log(this.inputChange('sharecard_title'))
    if (!this.inputChange('sharecard_title') || !this.inputChange('sharecard_description') || !this.inputChange('sharecard_thumbnap')) {
      this.$message.error('请填写必填项')
      if (this.sharecard_thumbnap == '') {
        this.sharecard_thumbnap_err = true
      }
      return
    } else if (this.sharecard_title == '' || this.sharecard_title == undefined) {
      this.$message.error('请填写必填项')
      this.sharecard_title_err = true
      return
    } else if (this.sharecard_description == '' || this.sharecard_description == undefined) {
      this.$message.error('请填写必填项')
      this.sharecard_description_err = true
      return
    } else if (this.sharecard_thumbnap == '' || this.sharecard_thumbnap == undefined) {
      this.$message.error('请填写必填项')
      this.sharecard_thumbnap_err = true
      return
    } else if (this.sharecard_description.length > this.max_description || this.sharecard_title.length > this.max_title) {
      return
    }
    else {
      /* if(this.sharecard_thumbnap.size/1024) */
      const res: any = await axios.put('/boss/setting/sharecard/0', params);
      if (res.status !== 200) {
        this.$message.error(res.message);
        return;
      }
      this.$message.success('保存成功');
    }


  }
  //获取数据
  async getDate() {
    const res: any = await axios.get('/boss/setting/sharecard/0');
    console.log(JSON.parse(JSON.stringify(res.data)))
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    } else if (JSON.stringify(res.data) == '{}') {
      return
    }
    else {
      this.sharecard_title = res.data.sharecard_title
      this.sharecard_description = res.data.sharecard_description
      this.sharecard_thumbnap = res.data.sharecard_thumbnap
    }
  }
  async created() {
    //this.$route;
    await this.getDate();
  }
}
